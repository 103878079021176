import { Hamburger } from "./Hamburger";
import { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
export {NavBar};

function NavBar(){
    const [ham, updateHam] = useState(false);
    
    function activeNav(ham){
        return(ham ? "active" : "");
    };

    // function currentPage(){
    //     let location = useLocation();
    //     console.log(location);
    // }
    function activeLink(loc, link){
        if(activePage(loc, link)){
            return("link-active");
        }
        return ""; 
    }

    function activePage(loc, link){
        if(loc.endsWith(link)){
            return true;
        }
        return false;
    }

    return(
     <nav className={activeNav(ham)}>
        <div className="container">
            <div className="icon">              
                <img src={require("./assets/logo-plain.png")} alt="inSTENT logo" className="ic" />
                <Link to="/home">
                    <h4>inSTENT</h4>
                </Link>
            </div>
            <div className="links">
                <Link 
                    to='/about' 
                    className={activeLink(useLocation().pathname, 'about')}>
                        About Us
                </Link>
                <Link 
                    to='/ourtechnology' 
                    className={activeLink(useLocation().pathname, 'ourtechnology')} >
                        Our Technology
                </Link>
                <Link 
                    to='/news'
                    className={activeLink(useLocation().pathname, 'news')}>
                        News
                </Link>
            </div>
           <Link
            to='/contact'
            aria-label="link to contact us page">
                <button className="contact">CONTACT US</button>
            </Link>
            <div className="hamburger"><Hamburger width={30} ham={ham} updateHam={updateHam} /></div>
            
        </div>
        <div className="hamContainer">
            <div className="hamlinks">
                <Link to="/about">About Us</Link>
                <Link to="/ourtechnology">Our Technology</Link>
                <Link to="/news">News</Link>
                <Link to="/contact">
                    <button className="hamContact">
                        CONTACT US
                    </button>
                </Link>
            </div>
        </div>
    </nav>
    )
};