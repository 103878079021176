import { NavBar } from './nav';
import { Footer } from './footer';
import { Team } from './Team';
export { About }; 

function About(){
    return(
        <div className="about">
            <div className="body">
                <NavBar/>
                <div className="abt-container-head">
                    <div className="abt-txt">
                        <div className="txt-wrapper">
                            <h2>About Us</h2>
                            <p>
                                We are dedicated towards providing safer, easier, and instant surgical connections to patients worldwide. 
                            </p>    
                        </div>
                    </div>
                    <div className="abt-img">
                        <img src={require('./assets/about/about-photo-1.jpg')} alt="" />
                    </div>

                </div>

                <div className="abt-container-mission">
                    <div className="abt-txt">
                        <div className="txt-wrapper">
                            <h3>The Mission</h3>
                            <h2>“providing safer, easier, and instant surgical connections to patients worldwide.”</h2>
                            <p>
                                Colon cancer resections often result in leakage of bacterial intestinal content at the surgical reconnection site. The inSTENT can be used to create these surgical connections without the risk of leakage. 
                            </p>    
                        </div>
                    </div>
                    <div className="abt-img">
                        <img src={require('./assets/about/grand-prize.jpg')} alt="" />
                    </div>
                </div>

                <div className="abt-container-problem">
                    <div className="abt-txt">
                        <div className="txt-wrapper">
                            <h3>The Problem</h3>
                            <h2>GI Anastomoses</h2>
                            <p>
                                Gastrointestinal (GI) anastomoses are surgical connections that are created between two separated sections of intestine. These surgical connections are essential to re-establish continuity following the separation of intestinal and bowel segments, yet traditional methods of creating them often result in leaking of GI content, which leads to severe health issues including infection, sepsis, and death. Among the approximately 700,000 GI surgeries performed worldwide involving anastomoses, leaking can occur in up to 30% of patients, and mortality rates from these leaks can be as high as 39%.
 
Anastomoses are usually made with staples and sutures, but these techniques fail to consistently create a watertight seal and prevent leaking. Because of this, surgical reoperation is the most common method of addressing leakage. However, this increases risk and cost for the patients and requires additional resources from surgeons and the hospital. 
 
In interviews conducted with surgeons, nurses, and hospital administration staff, there was a common sentiment shared that the current ways of addressing leakage are insufficient. Thus, there is an unmet need for a way to minimize anastomotic leaking in GI surgical patients so that leakage rates and postoperative complications are reduced.

                            </p>    
                        </div>
                    </div>
                    <div className="abt-img">
                        <div className="img-wrapper">
                            <img src={require('./assets/diagram.png')} alt="" />
                        </div>
                    </div>
                </div>


                <div className="team">

                </div>


            </div>
            <div className="team-wrapper">
                <Team />
            </div>
            <Footer />
        </div>
    );
}