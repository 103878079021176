import { NavBar } from "./nav";
import { Footer } from "./footer";
export { News }; 


function News() {
    return(

        <div className="news-flex-wrapper">
            <div className="news-wrapper">
                <div className="news">
                    <NavBar />
                    <div className="news-jumbo-container">
                        <div className="txt-wrapper">
                            <h3>In the News</h3>
                            <h2>Stories We're Featured In</h2>
                            
                        </div>
                        <Story
                            image= {require("./assets/about/about-photo-1.jpg")}
                            location= {"Seattle, WA"}
                            headline={"Safer urinary catheter, pulse oximeter for all skin colors and a GI stent win prizes at UW competition"}
                            date= {"March 4 2022"}
                            website= {"geekwire.com"}
                            id= {"main"}
                            to={"https://www.geekwire.com/2022/safer-urinary-catheter-pulse-oximeter-for-all-skin-colors-and-a-gi-stent-win-prizes-at-uw-competition/"}/>
                 
                        <Story
                            image= {require("./assets/instent-team-photo.png")}
                            location= {"Seattle, WA"}
                            headline={"Historic Finish to 25th Dempsey Startup Competition"}
                            date= {"May 27 2022"}
                            website= {"blog.foster.uw.edu"}
                            to={"https://blog.foster.uw.edu/2022-awards-dempsey-startup-competition/"} />
                        <Story
                            image= {require("./assets/news/holloman.png")}
                            location= {"Seattle, WA"}
                            headline={"Future Looks Bright at 2022 Hollomon Health Innovation Challenge"}
                            date= {"March 3 2022"}
                            website= {"blog.foster.uw.edu"}
                            to={"https://blog.foster.uw.edu/2022-health-innovation-challenge-prizes/"} />
                        <Story
                            image= {require("./assets/news/mdnext.png")}
                            location= {"Seattle, WA"}
                            headline={"Healthcare Startups Feature"}
                            date= {"Q3 2022"}
                            website= {"mdnext"}
                            id= {"main"}
                            to= {"https://issuu.com/mdnext/docs/md-next-q3-22_digital"} />
                    </div>
                </div>

            </div>
            <Footer />
        </div>


    );
}


function Story({image, location, headline, date, website, id, to}) {
    return(
            <a href={to} className="story" id={id}>

                <img src={image} alt={headline}/>
                <div className="info">
                    <h3 className="location" style={{textTransform: "uppercase"}}>{location}</h3>
                    <h2 className="headline">{headline}</h2>
                    <h4 className="date">{date}</h4>
                    <h4 className="website">{website}</h4>
                </div>
            </a>
        
    );
}

function NewsJumbotron({ image, location, headline, date, website}) {
    return(
        <div className="news-jumbotron">
            <img src={image} alt={headline}/>
            <div className="info">
                <h3 className="location" style={{textTransform: "uppercase"}}>{location}</h3>
                <h2 className="headline">{headline}</h2>
                <h4 className="date">{date}</h4>
                <h4 className="website">{website}</h4>
            </div>
        </div>
    );
            
}