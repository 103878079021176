import {NavBar} from './nav.jsx';
import {Hamburger} from './Hamburger.jsx';
import { Jumbotron } from './jumbotron';
import { Numbers } from './numbers';
import { Who } from './who';
import{ Footer } from './footer';
export { Home };


function Home(){
    return(
    <div className='home'>
      <div className="body">
        <NavBar />
        <Jumbotron />
        <Numbers numbers={['4', '5', '$80,000', 'Seattle, WA']} descriptions={['engineers' , 'advisors', 'funding raised', 'location']} />
        <Who />
      </div>
      <Footer />
    </div>
  );
}