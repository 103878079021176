import teamData from './teammembers.json';
import { useState } from "react";
import { Person } from './Person';
export { Team };



function Team(){
    const [selected, setSelected] = useState("Gillian Pereira");
    const [selectedTeam, setSelectedTeam] = useState("Executive Team");
   

    const changeActive = function(name, team){
        setSelected(name);
        setSelectedTeam(team);
    }




    const getTeams = () => {
        const exec = [];
        const adv = [];
        const parent = []; 

        for(const person of Object.keys(teamData['Executive Team'])){
            // console.log('person', person);
            // console.log('path', teamData['Executive Team'][person].path);
            exec.push(<Person key={person} name={person} path={teamData['Executive Team'][person].path} team={"Executive Team"} onPress={changeActive} />);

        }
        for(const person of Object.keys(teamData['Advisory Team'])){
            adv.push(
                <Person key={person} name={person} path={teamData['Advisory Team'][person].path} team={"Advisory Team"} onPress={changeActive} />
            );
        }

        parent.push(
            <h3 className="exec">Executive Team</h3>
        );
        parent.push(exec);
        parent.push(
            <h3 className="adv">Advisory Team</h3>
        );
        parent.push(adv);
        // console.log('parent', parent);
        return(parent);
        
    }



    const splitTeams = arr => {
        return(
            <div className="people-container">
                
                <div className="people-wrapper">
                    
                    {arr.shift()}
                    <div className="ppl-container">
                        {arr.shift()}
                    </div>
                </div>
                <div className="people-wrapper">
                    {arr.shift()}
                    <div className="ppl-container">
                        {arr.shift()}
                    </div>
                </div>
            </div>
        );
    }



    console.log(Object.keys(teamData));


let Chip = ({company}) => {
    return (
        <div className="chip">
            {company}
        </div>
    );
}


    return(
        <div className="team-container">
            <div className="txt-wrapper">
                <h3>The Team</h3>
                <h2>Get to Know Us</h2>
            </div>
            <div className="description-container">
                <div className="just-img">
                    <img src={require("./assets/people/" + teamData[selectedTeam][selected].path + ".png")} alt="" />
                    
                </div>
                <div className="desc">
                    <div className="name-title">
                        <h2>{selected}</h2>
                        <div className="title-chip">
                            <h4>{teamData[selectedTeam][selected].title}</h4>
                            <Chip company={teamData[selectedTeam][selected].company}/>
                        </div>
                    </div>
                    <p>{teamData[selectedTeam][selected].description}</p>
                </div>
            </div>
            <h3 id='description'>Click on a team member to learn more</h3>
            {splitTeams(getTeams())}
        </div>
    );
}


