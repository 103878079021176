import './App.css';
import {Home } from './Home';
import { About } from './About';
import { OurTechnology } from './OurTechnology';
import { News } from './News';
import { Contact } from './Contact';
import { Routes, Route } from 'react-router-dom';

function App() {
  return(
    <Routes>
      <Route path='*' element= {<Home />}/>
      <Route path='about' element= {<About />}/>
      <Route path='ourtechnology' element= {<OurTechnology />}/>
      <Route path='news' element= {<News />}/>
      <Route path='contact' element= {<Contact />}/>

    </Routes>
  );
}




export default App;
