import { NavBar } from "./nav";
import { Footer } from "./footer";
export {OurTechnology}

function OurTechnology(){
    return(
        <div className="ourtechnology">
            <NavBar />
            <div className="body">
                
                <div className="ourtechnology-flex-wrapper">
                    <div className="ourtechnology-content">
                        <div className="txt-container">
                            <div className="txt-wrapper">
                                <h3>Our Technology</h3>
                                <h2>Introducing Instent</h2>
                            </div>
                            <p>Introducing the inSTENT– the first bioresorbable intestinal stent for creating safe and leak-free surgical connections within the gastrointestinal tract

                            Our patent-pending device is a tubular stent that is placed inside the intestine and secured with micro-barbs accompanied by an external compressive band. This is the first bioresorbable stent designed specifically for the use of creating GI anastomoses and minimizing leakage in these surgical connections.
                            The stent will be placed inside and between the two organs that need to be surgically connected to each other. The barbs on the exterior of the stent will prevent migration of the device. Once the stent is implanted, the compressive band will be secured outside the intestine, centered at the surgical site. The band will clamp the intestine ends to the stent and to act as an additional barrier against leaking of the GI content from the surgical site. Once fixed into place, the opening of the stent allows for normal flow of GI content through the GI tract and allows for normal function as the wound heals. Once the wound has healed, the internal stent and compressive band will degrade over time, leaving only the new tissue growth over the surgical site. We have successfully 3D printed multiple iterations of our stent and have performed preliminary testing as proof of concept. We are continuing our optimization process for the barbs, testing different stent geometries, and planning quantitative testing to compare our device to current market solutions. 
</p>
                        </div>
                    </div>
                    <div className="ourtechnology-video">
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/LT1kCnemIo0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        
                    </div>
                </div>
            </div>
            <Footer /> 
        </div>
    );
}