import './hamburger.css';
import {useState} from 'react';
export {Hamburger};

function Hamburger({width, ham, updateHam}){
    const buttonStyle={
        // position: 'relative', 
        // top: '10em',
        // left: '10em'
    }

    

    function handleHamClick(){
        console.log("handleHamClick");
        if(ham){

            updateHam(false);

        }
        else{

            updateHam(true)
        }
    }

    function hamClassName(ham){

        return ham ? "ham active" : "ham";
    } 

    return(
        <button className={hamClassName(ham)} style={buttonStyle} onClick={handleHamClick} aria-label="toggle the menu" aria-expanded={ham}>
            <svg className="burger" viewBox="0 0 100 100" width={width}>
                <rect className="top" 
                    width="80" height="10" 
                    x="10" y="40" rx="5" >
                </rect>                
                <rect className="bottom" 
                    width="80" height="10" 
                    x="10" y="60" rx="5" >
                </rect>                
            </svg>
        </button>
    );
}