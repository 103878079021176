import { Link } from 'react-router-dom';
export {Footer}; 

function Footer(){
    return(
        <footer className="foot">
            <div className="imgContainer">
                <img src={require('./assets/logo.png')} alt="" />
            </div>
            <ul>
                <li><Link to="/ourtechnology">our technology</Link></li>
                <li><Link to="/about">about us</Link></li>
                <li><Link to="/news">news</Link></li>
                <li><Link to="/contact">contact us</Link></li>
            </ul>
        </footer>
    )

}