export {Numbers};



function Pane({number, description}){
    return(
        <div className="pane">
            <h3 className="number">{number}</h3>
            <h4 className="description">{description}</h4>
        </div>
    );
}

function Numbers({numbers, descriptions}){
    return(
        <div className="nums">
            <Pane number={numbers[0]} description={descriptions[0]} />
            <Pane number={numbers[1]} description={descriptions[1]} />
            <Pane number={numbers[2]} description={descriptions[2]} />
            <Pane number={numbers[3]} description={descriptions[3]} />
        </div>
    );
}