import { NavBar } from "./nav";
import { useState } from "react";
import { send } from 'emailjs-com';
import { Footer } from "./footer";
export {Contact}


function Contact(){
   
    return(
        <div className="contact-us">
            <NavBar/>

            <div className="contact-flex-wrapper">
                <div className="body">
                    <div className="txt-wrapper">
                            <h2>Contact Us</h2>
                            <p>You can email us at <a href="mailto:instentconnection@gmail.com">instentconnection@gmail.com</a>, or use the form below to send us a message. </p>
                    </div>
                    <ContactForm/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}



function ContactForm(){
    const [toSend, setToSend] = useState({
        from_name: '',
        user_email: '',
        message: '',
    });

    const [buttonDisabled, setButtonDisabled] = useState(false);



    const onSubmit = (e) => {
        e.preventDefault();
         send(
            'service_y5x47w6',
            'template_t5cu56d',
            toSend,
            'AkPhsQmPC-dyLvxyb'
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setButtonDisabled(true);
            setToSend({
                from_name: '',
                user_email: '',
                message: ''});
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });
  };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };


    
    
    
    return(
        <div className="contact-form">
            <form onSubmit={onSubmit}>
                <div className="input-box">
                    <label htmlFor="from_name">Name</label>
                    <input
                        type='text'
                        size='40'
                        required
                        name='from_name'
                        placeholder='your name'
                        value={toSend.from_name}
                        onChange={handleChange}
                    />
                </div>
                <div className="input-box">
                    <label htmlFor="user_email">Email</label>
                    <input
                        type='email'
                        name='user_email'
                        size= '40'
                        required
                        placeholder='your email'
                        value={toSend.user_email}
                        onChange={handleChange}
                    />
                </div>
                <div className="input-box">
                    <label htmlFor="message">Message</label>
                    <textarea
                        type='text'
                        required
                        name='message'
                        placeholder='Your message'
                        rows='5'
                        cols='39'
                        value={toSend.message}
                        onChange={handleChange}
                    />
                </div>
                <button type='submit' disabled={buttonDisabled} id="email-send">{buttonDisabled ? "Submitted ✅" : "Submit"}</button>
            </form>
        </div>
    );
        
}



