import { Link } from 'react-router-dom';
import waves from './assets/waves.svg';
export {Who};


function Who(){
    return(
        <div className="whoContainer" style={{ backgroundImage: `url(${waves})` }}>
            <div className="who">
                <div className="txt">
                    <h2>Who We Are</h2>
                    <p>We are a dedicated team of engineers, scientists, and doctors working towards eliminating a surgical complication that has been referred to as “the bane of intestinal surgery for over a century.” Our collaborative team has worked together to design a solution to this pressing issue. Contact us to learn more about our team and the inSTENT!</p>
                    <Link to="/about"><button type="button" className="learn-more">About Us</button></Link>
                </div>
                
                <img src={require("./assets/instent-team-photo.png")} alt="image of the team with a check for $7500 for winning fourth place in the Dempsey Startup Competition "></img>
            </div>
            <div className="who" >
                <div className="quote"><h2>"...addressed leakage and patient outcomes in a way that traditional methods do not"</h2> <p> - UW Foster Blog</p></div>
                <div className="txt">
                    <h2>In The News</h2>
                    <p>We have been featured in publications including the UW Foster Blog, and the MDNext magazine </p>
                    <Link to='/news'><button type="button" className="learn-more">Learn More</button></Link>
                </div>
            </div>
            <div className="interested">
                <h2>Interested in Learning More?</h2>
                <Link to="/contact"><button>Contact Us</button></Link>
            </div>
        </div>
        
    );

}