export { Person };

function Person({name, path, team, onPress}){
    return(
        <div className="person" onClick={() => onPress(name, team)}>
            <img src={require('./assets/people/' + path + '.png')} alt="" />
            <h3>{name}</h3>
        </div>
    );
}
