import { Link } from "react-router-dom";
export {Jumbotron};

function Jumbotron(){
    return(
        <div className="jumbotron">
            <div className="title-text">
                <h1 id="jumbo-main">Providing safer, easier, and instant surgical connections to patients worldwide</h1>
                <h2 id="jumbo-description">
                    {/* This should be a short description of what the company does, and an explanation of the line above the blurb. It should be around two to three sentences long */}
                </h2>
                <Link to="/about">
                    <button type="button" className="learn-more">
                        Learn More
                    </button>
                </Link>
            </div>
            <div className="stentContainer">
                <img src={require("./assets/stent.png")} alt="rendering of inSTENT stent" className="stent"/>   

            </div>
            
        </div>
    )
}